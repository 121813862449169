<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Tracks of a Killer - Week 1: Prelab Question 2</h2>

      <p class="mb-4">
        Consider the table of data below that was collected for a 1 mole sample of oxygen gas at a
        constant pressure.
      </p>

      <v-simple-table style="margin-left: 15px; margin-bottom: 20px; width: 200px" dense>
        <thead>
          <th style="text-align: center">
            <stemble-latex content="$\text{T (}^\circ\text{C)}$" />
          </th>
          <th style="text-align: center">
            <stemble-latex content="$\text{V (L)}$" />
          </th>
        </thead>
        <tbody>
          <tr v-for="(tempValue, i) in tempValues" :key="tempValue">
            <td style="text-align: center">
              {{ tempValues[i].toFixed(2) }}
            </td>
            <td style="text-align: center">
              {{ volumeValues[i].toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        a) Which property represents the independent variable? Explain your answer.
      </p>

      <p class="mb-3">
        <s-textarea auto-grow v-model="inputs.indVariable" class="mb-4" outlined />
      </p>

      <p class="mb-2">
        b) Prepare a graph of these data using Excel or Google Sheets. Be sure to label your axes,
        including units, and add an informative title. Include a linear trendline and display this
        equation on your graph to help you answer the later questions in this task. Please upload
        your graph using the input field below. See the video
        <a
          href="https://www.youtube.com/watch?v=JoLpTefCIzk&t=7s"
          rel="noopener noreferrer"
          target="_blank"
          >here</a
        >
        for an example of how to plot a graph in Excel with a trendline and equation.
      </p>

      <p><b>Accepted file types</b>: PDF, JPG, PNG, XLSX, XLS</p>

      <v-row class="mb-5 pl-4">
        <v-file-input v-model="graphUpload" multiple />
      </v-row>

      <p class="mb-2">
        c) Is the linear trendline on your graph a good fit for your data? How do you know?
      </p>

      <p class="mb-3">
        <s-textarea auto-grow v-model="inputs.precision" class="mb-4" outlined />
      </p>

      <p class="mb-2">
        d) Based on your graph, provide an approximate value for the volume of oxygen at
        <stemble-latex content="$100\,^\circ\text{C?}$" />
      </p>

      <calculation-input
        v-model="inputs.volAt100C"
        class="mb-6"
        prepend-text="$\text{V}_{\text{O}_2}:$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">e) Determine the slope of the line.</p>

      <v-row class="mb-4">
        <v-col cols="5">
          <calculation-input
            v-model="inputs.slopeInput"
            class="mb-6"
            prepend-text="$\text{Slope}:$"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="inputs.slopeUnits"
            prefix="Units:"
            outlined
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-2">
        f) What is the significance of the slope on your graph? What does it tell us about oxygen?
      </p>

      <p class="mb-3">
        <s-textarea auto-grow v-model="inputs.slopeSignificance" class="mb-4" outlined />
      </p>

      <p class="mb-2">g) What is the y-intercept of your trendline?</p>

      <v-row class="mb-4">
        <v-col cols="5">
          <calculation-input
            v-model="inputs.yintInput"
            class="mb-6"
            prepend-text="$\text{y-Int}:$"
            :disabled="!allowEditing"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="inputs.yintUnits"
            prefix="Units:"
            outlined
            :disabled="!allowEditing"
          />
        </v-col>
      </v-row>

      <p class="mb-2">
        h) What is the significance of the y-intercept on your graph? What does it tell us about
        oxygen?
      </p>

      <p class="mb-3">
        <s-textarea auto-grow v-model="inputs.yIntSignificance" class="mb-4" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';
import STextarea from '@/common/components/STextarea.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'OleMissExperiment9PrelabQuestion2',
  components: {CalculationInput, STextarea, AiLoadingOverlay, StembleLatex},
  mixins: [DynamicQuestionMixin()],

  data() {
    return {
      inputs: {
        indVariable: null,
        precision: null,
        volAt100C: null,
        slopeInput: null,
        slopeUnits: null,
        slopeSignificance: null,
        yintInput: null,
        yintUnits: null,
        yIntSignificance: null,
      },
      graphUpload: [],
    };
  },
  computed: {
    slope() {
      return 0.082057366;
    },
    intercept() {
      return 22.41397;
    },
    tempValues() {
      const start = 0;
      const stop = 6;
      return new Array(stop - start).fill(0).map((_, i) => 5 * i + 25.0);
    },
    volumeValues() {
      return this.tempValues.map((temp) => this.slope * temp + this.intercept);
    },
    equation() {
      if (this.inputs.depVariable === null) {
        return '$\\textbf{Equation: }\\text{Please enter dependent variable}$';
      } else if (this.inputs.slopeInput === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = $`;
      } else if (this.inputs.indepVariable === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}$`;
      } else if (this.inputs.interceptInput === null) {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}\\, ${this.inputs.indepVariable}$`;
      } else {
        return `$\\textbf{Equation: }${this.inputs.depVariable} = ${this.inputs.slopeInput}\\, ${this.inputs.indepVariable} ${this.inputs.interceptInput}\\,^\\circ\\text{C}$`;
      }
    },
    attachments() {
      return [...this.graphUpload];
    },
  },
});
</script>
